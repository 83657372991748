import _ from "lodash";
export default {
  data() {
    return {
      customers: {
        loading: false,
        pagination: {
          page: 1,
          perPage: 10,
          total: 0,
          totalPages: 0,
        },
        details: {},
        list: [],
        filters: {
          loadingResults: false,
          completeCustomer: false,
          users: {
            list: [],
            selected: [],
          },
          airlines: {
            list: [],
            selected: [],
          },
          milesPrograms: {
            list: [],
            selected: [],
          },
          status: {
            list: [
              {
                label: "Ativo",
                value: "Ativo",
              },
              {
                label: "Desativado",
                value: "Desativado",
              },
            ],
            selected: ["Ativo"],
          },
          period: {
            selected: [],
          },
          states: {
            selected: [],
          },
          supplier: "",
          id: "",
          searchTerm: "",
          customerName: "",
          haveCPF: false,
          available_miles: "",
          price: {
            min: "",
            max: "",
          },
          order: "desc",
          orderBy: "created",
        },
      },
      tempCustomer: {},
      createCustomer: false,
      customersTableCollumns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          class: "id",
          width: 80,
          sorter: true,
        },
        // {
        //   title: "Avaliação",
        //   scopedSlots: {
        //     customRender: "rate",
        //   },
        //   width: 170,
        // },
        {
          title: "Nome Cliente/Empresa",
          key: "name",
          width: 250,
          scopedSlots: {
            customRender: "name",
          },
        },
        {
          title: "E-mail",
          dataIndex: "email",
          key: "email",
          scopedSlots: {
            customRender: "email",
          },
          width: 350,
        },

        {
          title: "Celular",
          dataIndex: "mobile_phone",
          key: "mobile_phone",
          scopedSlots: {
            customRender: "mobile_phone",
          },
          width: 150,
        },
        {
          title: "Telefone Fixo",
          dataIndex: "phone",
          key: "phone",
          scopedSlots: {
            customRender: "phone",
          },
          width: 150,
        },
        {
          title: "Estado",
          dataIndex: "location_state",
          key: "location_state",
          width: 80,
          scopedSlots: {
            customRender: "location_state",
          },
        },
        {
          title: "Criado em",
          dataIndex: "created",
          key: "created",
          sorter: true,
          width: 200,
        },
        // {
        //   title: "",
        //   key: "user",
        //   scopedSlots: {
        //     customRender: "user",
        //   },
        // },
        {
          title: "",
          key: "action",
          align: "right",
          fixed: "right",

          scopedSlots: {
            customRender: "action",
          },
        },
      ],
    };
  },
  methods: {
    searchCustomer: _.debounce(function (e) {
      const serachText = e?.target?.value ? e.target.value : e;
      this.searchCustomers(1, serachText);
    }, 400),
    mapCustomersList(arr) {
      return arr.map(
        ({ id, person_type, trading_name, first_name, last_name }) => {
          let theLabel = ``;

          if (person_type === "Pessoa Física") {
            theLabel = `${first_name} ${last_name}`;
          } else {
            theLabel = `${trading_name}`;
          }

          return {
            label: `${id} - ${theLabel}`,
            value: id,
          };
        }
      );
    },
    searchCustomers(page, searchText) {
      this.loading = true;
      const params = {
        page,
        s: searchText,
        per_page: 10,
      };
      this.$http
        .get("/customer/list", {
          params,
        })
        .then(({ data }) => {
          this.customers.list = data.data;
        })
        .catch((error) => {
          error;
          this.customers.list = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onCustomerSelected(id) {
      this.customers.details = {};
      this.createCustomer = false;
      this.getCustomer(id);
      this.tempContract.customer_id = id;
    },
    getCustomer(id) {
      this.$http
        .post("/customer/details", {
          id,
        })
        .then(({ data }) => {
          this.customers.details = data;

          this.customerForm.setFieldsValue(data);
          if (this.$route.path.split("/")[1] == "customers") {
            if (data.person_type == "Pessoa Física") {
              document.title = `${data.id} - ${data.first_name}  ${data.last_name}`;
            } else {
              document.title = `${data.id} - ${data.trading_name} `;
            }
          }

          // CLIENTE TEMPORARIO
          this.tempCustomer = {
            ...data,
          };

          let allFieldsSize = Object.values(data.meta).length,
            values = Object.values(data.meta),
            keys = Object.keys(data.meta);

          for (let i = 0; i < allFieldsSize; i++) {
            this.tempCustomer[keys[i]] = values[i];
          }

          this.tempCustomer.status =
            data.status == 1 || data.status == "Ativo" ? "Ativo" : "Desativado";

          this.tempCustomer.user_id = data.user.id;
          delete this.tempCustomer.meta;

          this.tempContract.the_customer = { ...data, ...data.meta };
          delete this.tempContract.the_customer.meta;

          this.$refs.customerForm.$emit("setCustomerFields");
        })
        .catch(({ response }) => {
          response;
        });
    },
    async getCustomersList() {
      try {
        let filters = "";
        if (!this.$root.isAdmin()) {
          //  filters += `&user_id=${this.$store.state.userData.id}`;
        } else {
          if (this.customers.filters.users.selected.length > 0)
            filters += `&user_id=${this.customers.filters.users.selected}`;
        }

        if (this.customers.filters.states.selected.length)
          filters += `&location_state=${this.customers.filters.states.selected}`;

        if (this.customers.filters.completeCustomer)
          filters += `&complete_customer=true`;

        if (this.customers.filters.status.selected)
          filters += `&status=${this.customers.filters.status.selected}`;

        if (this.customers.filters.searchTerm)
          filters += `&s=${this.customers.filters.searchTerm}`;

        if (this.customers.filters.haveCPF)
          filters += `&have_cpf=${this.customers.filters.haveCPF}`;

        if (this.customers.filters.customerName)
          filters += `&customer_name=${this.customers.filters.customerName}`;

        filters += `&order=${this.customers.filters.order}&order-by=${this.customers.filters.orderBy}`;

        const { data } = await this.$http.get(
          `/customer/list?page=${this.customers.pagination.page}&per_page=${this.customers.pagination.perPage}${filters}`
        );
        return data;
      } catch (e) {
        e;
      }
    },
    getCustomers(samePage) {
      this.customers.loading = true;

      let filters = "";
      if (!this.$root.isAdmin()) {
        //  filters += `&user_id=${this.$store.state.userData.id}`;
      } else {
        if (this.customers.filters.users.selected.length > 0)
          filters += `&user_id=${this.customers.filters.users.selected}`;
      }

      if (this.customers.filters.states.selected.length)
        filters += `&location_state=${this.customers.filters.states.selected}`;

      if (this.customers.filters.completeCustomer)
        filters += `&complete_customer=true`;

      if (this.customers.filters.status.selected)
        filters += `&status=${this.customers.filters.status.selected}`;

      if (this.customers.filters.searchTerm)
        filters += `&s=${this.customers.filters.searchTerm}`;

      if (this.customers.filters.customerName)
        filters += `&customer_name=${this.customers.filters.customerName}`;

      filters += `&order=${this.customers.filters.order}&order-by=${this.customers.filters.orderBy}`;

      this.$http
        .get(
          `/customer/list?page=${this.customers.pagination.page}&per_page=${this.customers.pagination.perPage}${filters}`
        )
        .then(({ data }) => {
          if (!samePage) {
            this.customers.pagination.page = 1;
          }
          this.customers.pagination.total = data.meta.total;
          this.customers.pagination.total_general = data.meta.total_general;
          this.customers.pagination.totalPages = data.meta.total_pages;

          this.customers.list = data.data;
          this.excelFile.data = data.data;
        })
        .catch(({ response }) => {
          this.customers.pagination.total = response.data.meta.total;
          this.customers.pagination.totalPages = response.data.meta.total_pages;
          this.customers.pagination.total_general =
            response.data.meta.total_general;

          this.customers.list = [];
        })
        .finally(() => {
          this.customers.loading = false;
        });
    },
  },
};
