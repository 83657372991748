import _ from "lodash";

export default {
  data() {
    return {
      loading: false,
      suppliers: [],
      pagination: {
        page: 1,
        perPage: 20,
        total: 0,
        totalPages: 0,
      },
      supplier: {
        loading: false,
        pagination: {
          page: 1,
          perPage: 20,
          total: 0,
          totalPages: 0,
        },
        details: {},
        list: [],
        filters: {
          s: "",
          name: "",
          users: {
            selected: [],
          },
          order: "desc",
          orderBy: "created",
        },
      },
      processing: false,
    };
  },
  methods: {
    searchSupplier: _.debounce(function (e) {
      const serachText = e?.target?.value ? e.target.value : e;
      this.searchSuppliers(1, serachText);
    }, 400),
    mapSuppliersList(arr) {
      return arr.map(({ id, first_name, last_name }) => {
        return {
          label: `${id} - ${first_name} ${last_name}`,
          value: id,
        };
      });
    },
    changeSupplierPage(current) {
      this.supplier.pagination.page = current;
      this.getSuppliers();
    },
    changeSupplierPageSize(current, pageSize) {
      this.supplier.pagination.page = current;
      this.supplier.pagination.perPage = pageSize;

      this.getSuppliers();
    },
    onPageChange({ current }) {
      this.getsupplier(current);
    },
    supplierTableChange(pagination, filters, sorter) {
      console.log("sorter s", sorter, pagination, filters);
      this.supplier.filters.order =
        sorter.order != undefined ? sorter.order : "desc";
      this.supplier.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getsupplier();
    },
    searchSuppliers(page, searchText) {
      this.loading = true;
      const params = {
        page,
        s: searchText,
        per_page: 30,
      };
      this.$http
        .get("/supplier/search", {
          params,
        })
        .then(
          ({
            data: {
              data,
              meta: { total },
            },
          }) => {
            this.pagination.total = total;
            this.suppliers = [...data];
          }
        )
        .catch((error) => {
          console.log(error);
          this.suppliers = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getSupplier(id) {
      this.processing = true;
      this.$http
        .post("/supplier/details", {
          id,
        })
        .then(({ data }) => {
          delete data.user;
          this.$root.$emit("getSupplierRes", {
            ...data,
          });
        })
        .catch(
          ({
            response: {
              data: { message },
            },
          }) => {
            this.$message.error(message);
          }
        )
        .finally(() => {
          this.processing = false;
        });
    },
    getSuppliers(samePage) {
      this.supplier.loading = true;

      let filters = "";
      if (!this.$root.isAdmin()) {
        filters += `&user_id=${this.$store.state.userData.id}`;
      } else {
        if (this.supplier.filters.users.selected.length > 0) {
          filters += `&user_id=${this.supplier.filters.users.selected}`;
        }
      }

      // if (this.supplier.filters.states.selected) {
      //   filters += `&location_state=${this.supplier.filters.states.selected}`;
      // }

      // if (this.customers.filters.status.selected) {
      //   filters += `&status=${this.customers.filters.status.selected}`;
      // }

      if (this.supplier.filters.name) {
        filters += `&name=${this.supplier.filters.name}`;
      }

      // if (this.supplier.filters.customerName) {
      //   filters += `&customer_name=${this.supplier.filters.customerName}`;
      // }

      filters += `&order=${this.supplier.filters.order}&order-by=${this.supplier.filters.orderBy}`;

      this.$http
        .get(
          `/supplier/list-v2?page=${this.supplier.pagination.page}&per_page=${this.supplier.pagination.perPage}${filters}`
        )
        .then(({ data }) => {
          if (!samePage) {
            this.supplier.pagination.page = 1;
          }
          this.supplier.pagination.total = data.meta.total;
          this.supplier.pagination.total_general = data.meta.total_general;
          this.supplier.pagination.totalPages = data.meta.total_pages;
          this.supplier.list = data.data;
        })
        .catch(({ response }) => {
          this.supplier.pagination.total = response.data.meta.total;
          this.supplier.pagination.totalPages = response.data.meta.total_pages;
          this.supplier.pagination.total_general =
            response.data.meta.total_general;

          this.supplier.list = [];
        })
        .finally(() => {
          this.supplier.loading = false;
        });
    },
  },
};
