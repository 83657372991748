<template>
  <div>
    <aRow class="content" :gutter="10">
      <aCol :span="24">
        <aRow :gutter="20">
          <a-col :span="10">
            <HayaSelectField
              label="Fornecedor"
              placeholder="Selecione ou busque nome"
              optionFilterProp="txt"
              :fieldKey="`credit_letter_miles_${id}_supplier_id`"
              :fieldClass="creditLetterFieldsDisabled ? 'readonly' : ''"
              :form="form"
              :required="true"
              :list="mapSuppliersList(supplier.list)"
              :allowClear="true"
              :showSearch="true"
              :loading="supplier.loading"
              @onSearchSelectField="onSearchSuppliers"
              @onSelectSelectField="onSelectSupplier"
            />
          </a-col>

          <a-col :span="6">
            <HayaSelectField
              label="Cartão de Milhas"
              placeholder="Selecione"
              optionFilterProp="txt"
              :fieldKey="`credit_letter_miles_${id}_miles_card_id`"
              :fieldClass="creditLetterFieldsDisabled ? 'readonly' : ''"
              :form="form"
              :required="true"
              :list="mapMilesCardsList(milesCards.list)"
              :allowClear="true"
              :showSearch="true"
              :loading="milesCards.loading"
            />
          </a-col>

          <a-col :span="4">
            <HayaValueField
              label="Quantidade de milhas"
              placeholder="Escreva"
              :fieldKey="`credit_letter_miles_${id}_miles_quantity`"
              :fieldClass="creditLetterFieldsDisabled ? 'readonly' : ''"
              :vCurrency="{
                currency: {
                  prefix: '',
                  suffix: '',
                },
                distractionFree: false,
                valueAsInteger: false,
                precision: 0,
                autoDecimalMode: true,
                allowNegative: false,
              }"
              :form="form"
              :required="true"
              :preIcon="true"
            />
          </a-col>

          <a-col :span="4">
            <HayaValueField
              label="Valor Fornecedor"
              placeholder="Escreva"
              :fieldKey="`credit_letter_miles_${id}_supplier_value`"
              :fieldClass="creditLetterFieldsDisabled ? 'readonly' : ''"
              :vCurrency="{}"
              :form="form"
              :required="true"
              :preIcon="true"
            />
          </a-col>

          <a-col :span="10">
            <HayaRadioField
              label="Status Fornecedor"
              :fieldKey="`credit_letter_miles_${id}_supplier_status`"
              :fieldClass="creditLetterFieldsDisabled ? 'readonly' : ''"
              :form="form"
              :required="true"
              :options="supplierStatus"
            />
          </a-col>

          <a-col :span="24">
            <HayaRadioField
              label="Status das Milhas"
              :fieldKey="`credit_letter_miles_${id}_miles_status`"
              :fieldClass="creditLetterFieldsDisabled ? 'readonly' : ''"
              :form="form"
              :required="true"
              :options="milesStatus"
            />
          </a-col>
        </aRow>
      </aCol>
    </aRow>
  </div>
</template>

<script>
// libs
import _ from "lodash";

// components
import HayaSelectField from "@/components/general/fields/HayaSelectField.vue";
import HayaRadioField from "@/components/general/fields/HayaRadioField.vue";
import HayaValueField from "@/components/general/fields/HayaValueField.vue";

//reuse
import creditLetterReusables from "../mixins/creditLetterReusables";
import suppliersMixins from "@/mixins/suppliers/suppliersMixins";
import milesCardsMixin from "@/mixins/miles-cards/milesCardsMixin";

export default {
  name: "CreditLetterMilesSection",
  props: {
    id: Number,
    form: Object,
    action: String,
    creditLetter: Object,
    creditLetterFieldsDisabled: Boolean,
  },
  components: {
    HayaSelectField,
    HayaValueField,
    HayaRadioField,
  },
  mixins: [suppliersMixins, creditLetterReusables, milesCardsMixin],
  data() {
    return {};
  },
  mounted() {
    this.supplier.pagination.perPage = 50;
    this.supplier.filters.name =
      this.creditLetter.details.meta[
        `credit_letter_miles_${this.id}_supplier_id`
      ];
    this.getSuppliers();

    if (
      this.creditLetter.details.meta[
        `credit_letter_miles_${this.id}_supplier_id`
      ] !== undefined
    ) {
      this.milesCards.filters.supplier =
        this.creditLetter.details.meta[
          `credit_letter_miles_${this.id}_supplier_id`
        ];

      this.getMilesCards();
    }

    this.form.setFieldsValue({
      [`credit_letter_miles_${this.id}_supplier_id`]:
        this.creditLetter.details.meta[
          `credit_letter_miles_${this.id}_supplier_id`
        ],
      [`credit_letter_miles_${this.id}_miles_card_id`]:
        this.creditLetter.details.meta[
          `credit_letter_miles_${this.id}_miles_card_id`
        ],
      [`credit_letter_miles_${this.id}_miles_status`]:
        this.creditLetter.details.meta[
          `credit_letter_miles_${this.id}_miles_status`
        ],
      [`credit_letter_miles_${this.id}_supplier_value`]:
        this.creditLetter.details.meta[
          `credit_letter_miles_${this.id}_supplier_value`
        ].replace(".", ","),
      [`credit_letter_miles_${this.id}_supplier_status`]:
        this.creditLetter.details.meta[
          `credit_letter_miles_${this.id}_supplier_status`
        ],
      [`credit_letter_miles_${this.id}_miles_quantity`]: this.creditLetter
        .details.meta[`credit_letter_miles_${this.id}_miles_quantity`]
        ? this.creditLetter.details.meta[
            `credit_letter_miles_${this.id}_miles_quantity`
          ]
            .replace(".", "")
            .replace(".", "")
            .replace(".", "")
            .replace(".", "")
        : undefined,
    });
  },
  methods: {
    onSearchSuppliers: _.debounce(function (e) {
      if (e.length > 1) {
        this.supplier.filters.name = e;
        this.getSuppliers();
      }
    }, 600),
    onSelectSupplier(id) {
      this.supplier.loading = false;
      this.form.setFieldsValue({
        [`credit_letter_miles_${this.id}_miles_card_id`]: undefined,
      });

      this.milesCards.list = [];
      this.milesCards.filters.supplier = id;
      this.getMilesCards();
    },
  },
};
</script>

<style lang="sass" scoped>
.content
  border-top: 1px solid #ccc
  padding: 20px 20px 0
  border-radius: 0 0 6px 6px
  background: #fff
</style>
