<template>
  <aFormItem class="hf-wrapper hf-datepicker-wrapper">
    <label v-if="label">{{ label }}</label>
    <div class="description" v-if="description">{{ description }}</div>
    <aDatePicker
      class="hf-datepicker"
      format="DD/MM/YYYY"
      v-mask="'##/##/####'"
      :class="fieldClass"
      :placeholder="placeholder || 'Selecione'"
      :showToday="false"
      :allowClear="true"
      :disabled="disabled"
      v-decorator="[
        fieldKey,
        {
          initialValue,
          rules: validationRules,
        },
      ]"
      @change="onChangeDatepicker"
    >
      <template v-if="hasPrefixContent" #prefix>
        <slot v-if="!preIcon" name="prefix"></slot>
        <a-icon v-if="preIcon" type="field-svg" />
      </template>
      <template v-if="hasSuffixContent" #suffix>
        <slot name="suffix"></slot>
      </template>
    </aDatePicker>
  </aFormItem>
</template>

<script>
export default {
  name: "HayaDatePickerField",
  inheritAttrs: false,
  props: {
    label: String,
    type: String,
    description: String,
    fieldKey: String,
    placeholder: String,
    fieldClass: String,
    requiredMessage: String,
    required: Boolean,
    initialValue: String,
    disabled: Boolean,
    list: Array,
    form: Object,
    readonly: Boolean,
    preIcon: Boolean,
  },
  data() {
    return {
      validationRules: [],
    };
  },
  computed: {
    hasSuffixContent() {
      // Check if the 'suffix' slot has content
      return !!this.$slots["suffix"];
    },
    hasPrefixContent() {
      // Check if the 'prefix' slot has content
      return this.preIcon === true ? true : !!this.$slots["prefix"];
    },
  },
  beforeMount() {
    if (this.required) {
      this.validationRules.push({
        required: true,
        message: this.requiredMessage ? this.requiredMessage : "Obrigatório",
      });
    }
  },
  methods: {
    onChangeDatepicker(e) {
      this.$emit("onChangeDatepicker", e);
    },
  },
};
</script>

<style lang="sass"></style>

<style lang="sass" scoped>
.hf-wrapper
  label
    font-size: 10px
    font-weight: 500
    color: #ccc
    line-height: 1
    text-overflow: ellipsis
    width: 100%
    white-space: nowrap
    margin-top: -10px
    margin-bottom: 2px
    display: block
  .description
    font-size: 9px
    line-height: 1.2
    margin: 8px  0 3px
    font-weight: 500
    border-left: 2px solid #ddd
    padding-left: 7px
</style>
