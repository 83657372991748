var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('aRow',{staticClass:"content",attrs:{"gutter":10}},[_c('aCol',{attrs:{"span":24}},[_c('aRow',{attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":10}},[_c('HayaSelectField',{attrs:{"label":"Fornecedor","placeholder":"Selecione ou busque nome","optionFilterProp":"txt","fieldKey":`credit_letter_miles_${_vm.id}_supplier_id`,"fieldClass":_vm.creditLetterFieldsDisabled ? 'readonly' : '',"form":_vm.form,"required":true,"list":_vm.mapSuppliersList(_vm.supplier.list),"allowClear":true,"showSearch":true,"loading":_vm.supplier.loading},on:{"onSearchSelectField":_vm.onSearchSuppliers,"onSelectSelectField":_vm.onSelectSupplier}})],1),_vm._v(" "),_c('a-col',{attrs:{"span":6}},[_c('HayaSelectField',{attrs:{"label":"Cartão de Milhas","placeholder":"Selecione","optionFilterProp":"txt","fieldKey":`credit_letter_miles_${_vm.id}_miles_card_id`,"fieldClass":_vm.creditLetterFieldsDisabled ? 'readonly' : '',"form":_vm.form,"required":true,"list":_vm.mapMilesCardsList(_vm.milesCards.list),"allowClear":true,"showSearch":true,"loading":_vm.milesCards.loading}})],1),_vm._v(" "),_c('a-col',{attrs:{"span":4}},[_c('HayaValueField',{attrs:{"label":"Quantidade de milhas","placeholder":"Escreva","fieldKey":`credit_letter_miles_${_vm.id}_miles_quantity`,"fieldClass":_vm.creditLetterFieldsDisabled ? 'readonly' : '',"vCurrency":{
              currency: {
                prefix: '',
                suffix: '',
              },
              distractionFree: false,
              valueAsInteger: false,
              precision: 0,
              autoDecimalMode: true,
              allowNegative: false,
            },"form":_vm.form,"required":true,"preIcon":true}})],1),_vm._v(" "),_c('a-col',{attrs:{"span":4}},[_c('HayaValueField',{attrs:{"label":"Valor Fornecedor","placeholder":"Escreva","fieldKey":`credit_letter_miles_${_vm.id}_supplier_value`,"fieldClass":_vm.creditLetterFieldsDisabled ? 'readonly' : '',"vCurrency":{},"form":_vm.form,"required":true,"preIcon":true}})],1),_vm._v(" "),_c('a-col',{attrs:{"span":10}},[_c('HayaRadioField',{attrs:{"label":"Status Fornecedor","fieldKey":`credit_letter_miles_${_vm.id}_supplier_status`,"fieldClass":_vm.creditLetterFieldsDisabled ? 'readonly' : '',"form":_vm.form,"required":true,"options":_vm.supplierStatus}})],1),_vm._v(" "),_c('a-col',{attrs:{"span":24}},[_c('HayaRadioField',{attrs:{"label":"Status das Milhas","fieldKey":`credit_letter_miles_${_vm.id}_miles_status`,"fieldClass":_vm.creditLetterFieldsDisabled ? 'readonly' : '',"form":_vm.form,"required":true,"options":_vm.milesStatus}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }