export default {
  data() {
    return {
      creditLetter: {
        loading: false,
        details: {},
        list: [],
        meta: {},
        filters: {
          id: "",
          code: "",
          voyageId: "",
          receiptDate: "",
          customerId: "",
          expires: {
            selected: [],
          },
          spent: {
            min: "",
            max: "",
          },
          users: {
            selected: [],
          },
          issuer: {
            selected: [],
          },
          companies: {
            list: [],
            selected: [],
          },
          companyBranches: {
            list: [],
            selected: [],
          },
          status: {
            list: [
              {
                label: "Aberto",
                value: "Aberto",
              },
              {
                label: "Ganho",
                value: "Ganho",
              },
              {
                label: "Perdido",
                value: "Perdido",
              },
            ],
            selected: [],
          },
          situation: {
            list: [
              {
                label: "Lixeira",
                value: "trash",
              },
            ],
            selected: [],
          },
          period: {
            selected: [],
          },
          price: {
            min: "",
            max: "",
          },
          order: "desc",
          orderBy: "created",
        },
        pagination: {
          page: 1,
          perPage: 20,
          total: 0,
          complete_list_total: 0,
          totalPages: 0,
        },
      },
      creditLettersTableColumns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          class: "id",
          width: 65,
          sorter: true,
        },
        {
          title: "Cliente",
          width: 220,
          scopedSlots: { customRender: "customer" },
        },
        {
          title: "Código",
          dataIndex: "code",
          key: "code",
          class: "code",
          width: 140,
          sorter: true,
        },
        {
          title: "Valor ",
          key: "value",
          width: 100,
          sorter: true,
          scopedSlots: { customRender: "value" },
        },
        {
          title: "Disponível",
          key: "available_value",
          width: 110,
          sorter: true,
          scopedSlots: { customRender: "available_value" },
        },
        {
          title: "Utilizado",
          key: "spent_value",
          width: 100,
          sorter: true,
          scopedSlots: { customRender: "spent_value" },
        },
        {
          title: "Validade",
          width: 120,
          scopedSlots: { customRender: "expires_at" },
        },
        {
          title: "Recebimento",
          width: 120,
          scopedSlots: { customRender: "receipt_date" },
        },
        {
          title: "Emissor",
          width: 80,
          scopedSlots: { customRender: "issuer" },
        },

        {
          title: "Cadastrado em:",
          width: 150,
          scopedSlots: { customRender: "created" },
        },
        {
          title: "",
          key: "action",
          align: "right",
          width: 60,
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
      fieldsArr: [
        {
          field: "profit_value",
          label: "Lucratividade original",
        },
        {
          field: "product_type",
          label: "Tipo de produto",
        },
        {
          field: "code",
          label: "Código",
        },
        {
          field: "expires_at",
          label: "Data de Validade",
        },
        {
          field: "value",
          label: "Valor (R$)",
        },
        {
          field: "company_id",
          label: "Empresa",
        },
        {
          field: "company_branch_id",
          label: "Filial",
        },
        {
          field: "customer_id",
          label: "Cliente",
        },
        {
          field: "status",
          label: "Status",
        },
        {
          field: "issuer_id",
          label: "Emissor",
        },
        {
          field: "receipt_date",
          label: "Data de Recebimento",
        },
        {
          field: "supplier_id",
          label: "Fornecedor",
        },
        {
          field: "supplier_value",
          label: "Valor Fornecedor",
        },
        {
          field: "supplier_status",
          label: "Status Fornecedor",
        },
        {
          field: "miles_status",
          label: "Status das Milhas",
        },
        {
          field: "voyage_id",
          label: "ID do Voyage",
        },
      ],
      milesStatus: [
        {
          label: "Milhas Ativas",
          value: "Milhas Ativas",
        },
        {
          label: "Milhas Expiradas",
          value: "Milhas Expiradas",
        },
        {
          label: "Milhas Vencidas",
          value: "Milhas Vencidas",
        },
      ],
      supplierStatus: [
        {
          label: "Pago",
          value: "Pago",
        },
        {
          label: "Pendente",
          value: "Pendente",
        },
        {
          label: "Cancelado",
          value: "Cancelado",
        },
      ],
      productsType: [
        {
          label: "Hotelaria",
          value: "Hotelaria",
          disabled: false,
        },
        {
          label: "Passagem Aérea Regular",
          value: "Passagem Aérea Regular",
          disabled: false,
        },
        {
          label: "Passagem Aérea Milhas",
          value: "Passagem Aérea Milhas",
          disabled: false,
        },
      ],
      status: [
        {
          label: "Auditoria",
          value: "Auditoria",
        },
        {
          label: "Ativo",
          value: "Ativo",
        },
        {
          label: "Finalizado",
          value: "Finalizado",
        },
        {
          label: "Pendente",
          value: "Pendente",
        },
        {
          label: "Jurídico",
          value: "Jurídico",
        },
      ],
      currencyList: [
        {
          label: "R$",
          value: "brl",
        },
        {
          label: "U$",
          value: "usd",
        },
        {
          label: "€",
          value: "eur",
        },
      ],
    };
  },
  methods: {
    emptyValsFormat(val) {
      let theVal = val;

      if ([undefined, "undefined", null, "null"].includes(val)) theVal = "' '";

      return theVal;
    },
    translateKeyToLabel(key) {
      let theLabel = key;

      this.fieldsArr.forEach(({ field, label }) => {
        if (field === key) {
          theLabel = label;
        }
      });

      return theLabel;
    },
    mapStatusList(arr) {
      return arr.map(({ name }) => {
        return {
          label: name,
          value: name,
        };
      });
    },
    changeCreditLetterPage(current) {
      this.creditLetter.pagination.page = current;
      this.getCreditLetters();
    },
    creditLetterTableChange(pagination, filters, sorter) {
      filters;
      pagination;
      console.log(filters, pagination);
      this.creditLetter.filters.order =
        sorter.order != undefined ? sorter.order : "desc";
      this.creditLetter.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getCreditLetters();
    },
    creditLetterChangePageSize(current, pageSize) {
      this.creditLetter.pagination.page = current;
      this.creditLetter.pagination.perPage = pageSize;
      this.getCreditLetters();
    },
    creditLetterFilters() {
      let filters = "",
        queryParams = new URLSearchParams("");
      history.pushState(null, null, "");

      // if (!this.$root.isAdmin()) {
      //   filters += `&user_id=${this.$store.state.userData.id}`;
      // } else {
      //   if (this.creditLetter.filters.users.selected.length > 0) {
      //     filters += `&user_id=${this.creditLetter.filters.users.selected}`;
      //     queryParams.set(
      //       "user",
      //       `${this.creditLetter.filters.users.selected}`
      //     );
      //   }
      // }

      if (this.creditLetter.filters.customerId) {
        filters += `&customer_id=${this.creditLetter.filters.customerId}`;
        queryParams.set(
          "customer_id",
          `${this.creditLetter.filters.customerId}`
        );
      }

      if (this.creditLetter.filters.code) {
        filters += `&code=${this.creditLetter.filters.code}`;
        queryParams.set("code", `${this.creditLetter.filters.code}`);
      }
      if (this.creditLetter.filters.receiptDate) {
        filters += `&receipt_date=${this.creditLetter.filters.receiptDate}`;
        queryParams.set(
          "receipt_date",
          `${this.creditLetter.filters.receiptDate}`
        );
      }

      if (this.creditLetter.filters.companies.selected.length > 0) {
        filters += `&company_id=${this.creditLetter.filters.companies.selected}`;
      }

      if (this.creditLetter.filters.issuer.selected.length > 0) {
        filters += `&issuer_id=${this.creditLetter.filters.issuer.selected}`;
      }

      if (this.creditLetter.filters.expires.selected.length > 0) {
        filters += `&expires=${this.creditLetter.filters.expires.selected}`;
      }

      if (this.creditLetter.filters.companyBranches.selected.length > 0) {
        filters += `&company_branch_id=${this.creditLetter.filters.companyBranches.selected}`;
      }

      if (
        this.creditLetter.filters.spent.min ||
        this.creditLetter.filters.spent.max
      ) {
        let min = this.creditLetter.filters.spent.min;
        let max = this.creditLetter.filters.spent.max;
        if (min) if (!max) max = "500000";
        if (max) if (!min) min = "0";
        filters += `&spent_range=${min}-${max}`;
      }

      if (this.creditLetter.filters.status.selected.length > 0) {
        filters += `&status=${this.creditLetter.filters.status.selected}`;
        queryParams.set(
          "status",
          `${this.creditLetter.filters.status.selected}`
        );
      }

      if (this.creditLetter.filters.period.selected.length > 0) {
        filters += `&period=${this.creditLetter.filters.period.selected[0]}|${this.creditLetter.filters.period.selected[1]}`;
        queryParams.set(
          "period",
          `${this.creditLetter.filters.period.selected[0]}|${this.creditLetter.filters.period.selected[1]}`
        );
      }

      if (this.creditLetter.filters.id) {
        filters += `&id=${this.creditLetter.filters.id}`;
      }

      filters += `&order=${this.creditLetter.filters.order}&order-by=${this.creditLetter.filters.orderBy}`;

      history.replaceState(null, null, "?" + queryParams.toString());

      return filters;
    },
    async getCreditLetterById(id) {
      try {
        const data = await this.$http.get(`/credit-letter/details?id=${id}`);
        this.creditLetter.details = data.data;
        return data;
      } catch (error) {
        console.log(error);
        this.$message.error("Houve algum erro ao carregar os dados.");
        this.creditLetter.details = {};
      }
    },
    async getCreditLetters(samePage) {
      this.creditLetter.loading = true;
      try {
        const { data } = await this.$http.get(
          `/credit-letter/list?page=${
            this.creditLetter.pagination.page
          }&per_page=${
            this.creditLetter.pagination.perPage
          }${this.creditLetterFilters()}`
        );

        this.creditLetter.list = data.data;
        this.creditLetter.meta = data.meta;
        this.creditLetter.pagination.page = data.meta.next_page;
        this.creditLetter.pagination.total = data.meta.total;

        if (!samePage) {
          this.creditLetter.pagination.page = 1;
        }
      } catch (e) {
        console.log(e);
        this.creditLetter.list = [];
        this.creditLetter.meta = {
          total: 0,
          total_available: "0,00",
          total_general: 0,
          total_pages: 0,
          total_spent: "0,00",
          total_value: "0,00",
        };
      } finally {
        this.creditLetter.loading = false;
      }
    },
  },
};
